import { getToken, removeToken, setToken } from '@/utils/local-auth'
import serviceProvider from '@/utils/service-provider'

const state = {
  id: null,
  token: getToken(),
  name: '',
  full_name: '',
  email: '',
  avatar: '',
  introduction: '',
  roles: [],
  permissions: [],
  meta: [],
  password_expired: false
}

const mutations = {
  SET_ID: (state, id) => {
    state.id = id
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_FULL_NAME: (state, fullName) => {
    state.full_name = fullName
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_META: (state, meta) => {
    state.meta = meta
  },
  SET_PASSWORD_EXPIRED: (state, passwordExpired) => {
    state.password_expired = passwordExpired
  }
}

const actions = {
  /**
   * login
   * @param commit
   * @param payload
   * @return {Promise<unknown>}
   */
  login ({ commit }, payload) {
    const {
      username,
      password
    } = payload
    return new Promise((resolve, reject) => {
      const authApi = serviceProvider.resolve('auth-api')
      const config = serviceProvider.resolve('config')
      authApi.csrf().then(() => {
        authApi.login(username.trim(), password.trim())
          .then(() => {
            setToken(1, config.cookiesPath, config.cookiesDomain)
            resolve(state)
          })
          .catch(error => {
            removeToken(config.cookiesPath, config.cookiesDomain)
            reject(error)
          })
      })
    })
  },
  /**
   * setPassword
   * @param commit
   * @param payload
   * @return {Promise<unknown>}
   */
  setPassword ({ commit }, payload) {
    const {
      password,
      newPassword,
      confirmPassword
    } = payload
    return new Promise((resolve, reject) => {
      const authApi = serviceProvider.resolve('auth-api')
      const userApi = serviceProvider.resolve('user-api')
      authApi.csrf().then(() => {
        userApi.setPassword(password, newPassword, confirmPassword)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    })
  },
  /**
   * resetPassword
   * @param commit
   * @param email
   * @return {Promise<unknown>}
   */
  resetPassword ({ commit }, email) {
    return new Promise((resolve, reject) => {
      const authApi = serviceProvider.resolve('auth-api')
      const userApi = serviceProvider.resolve('user-api')
      authApi.csrf().then(() => {
        userApi.resetPassword(email)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    })
  },
  /**
   * getInfo
   * @param commit
   * @param state
   * @return {Promise<unknown>}
   */
  getInfo ({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      const authApi = serviceProvider.resolve('auth-api')
      const config = serviceProvider.resolve('config')
      authApi.getInfo(state.token)
        .then(response => {
          const { data } = response
          if (!data) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Verification failed, please Login again.')
          }
          const {
            roles,
            name,
            // eslint-disable-next-line camelcase
            full_name,
            email,
            avatar,
            introduction,
            permissions,
            meta,
            id,
            // eslint-disable-next-line camelcase
            password_expired
          } = data
          // roles must be a non-empty array
          if (!roles || roles.length <= 0) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('getInfo: roles must be a non-null array!')
          }
          commit('SET_EMAIL', email)
          commit('SET_ROLES', roles)
          commit('SET_META', meta)
          commit('SET_PERMISSIONS', permissions)
          commit('SET_NAME', name)
          commit('SET_FULL_NAME', full_name)
          commit('SET_AVATAR', avatar)
          commit('SET_INTRODUCTION', introduction)
          commit('SET_ID', id)
          commit('SET_PASSWORD_EXPIRED', password_expired)
          setToken(1, config.cookiesPath, config.cookiesDomain)
          resolve(state)
        })
        .catch(error => {
          removeToken(config.cookiesPath, config.cookiesDomain)
          reject(error)
        })
    })
  },

  /**
   * logout
   * @param commit
   * @param state
   * @return {Promise<unknown>}
   */
  logout ({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      const config = serviceProvider.resolve('config')
      const authApi = serviceProvider.resolve('auth-api')
      authApi.csrf().then(() => {
        authApi.logout()
          .then(() => {
            commit('SET_AVATAR', '')
            commit('SET_ROLES', [])
            commit('SET_META', [])
            commit('SET_PERMISSIONS', [])
            commit('SET_ID', null)
            commit('SET_TOKEN', '')
            commit('SET_EMAIL', '')
            commit('SET_NAME', '')
            commit('SET_FULL_NAME', '')
            commit('SET_INTRODUCTION', '')
            commit('SET_PASSWORD_EXPIRED', false)
            removeToken(config.cookiesPath, config.cookiesDomain)
            resolve(state)
          })
          .catch(error => {
            reject(error)
          })
      })
    })
  }
}

const getters = {
  data: state => {
    return state
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
