/**
 * Is authorised middleware
 * @param authService
 * @param redirect
 * @param next
 * @param to
 * @return {*}
 */
export default function ({
  authService,
  redirect,
  next,
  to
}) {
  if (authService.isAuthorized()) {
    return next()
  }
  return redirect(__getUnauthorizedRoute(to, authService))
}

/**
 * __getUnauthorizedRoute
 * @param to
 * @return {string|null}
 * @private
 */
function __getUnauthorizedRoute (to, authService) {
  if (!authService.inWhiteList(to)) {
    return {
      path: '/login',
      query: __getQueryParams(to)
    }
  }
  authService.addBodyClasses(to)
}

/**
 * __toQueryParam
 * @param to
 * @return {{}}
 * @private
 */
function __getQueryParams (to) {
  const params = {}
  if (to.path !== '/') {
    params.redirect = to.path
  }
  if (to.query?.logout_redirect) {
    params.logout_redirect = to.query.logout_redirect
  }
  return params
}
