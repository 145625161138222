export default {
  login: {
    formTitle: 'Sign in to TripUp',
    signIn: 'Welcome to TripUp App',
    email: 'Username or email address',
    forgotPassword: 'Forgot your password?',
    password: 'Password',
    signInCaption: 'Sign in',
    capsLockOn: 'Caps lock is on'
  },
  resetPassword: {
    title: 'Reset password',
    subTitle: 'Enter your email address',
    submit: 'Reset password',
    emailPlaceholder: 'Email address',
    backToLogin: 'Return to login page'
  },
  setPassword: {
    title: 'Set new password',
    subTitle: 'Please fill in the fields to change your password',
    submit: 'Change password',
    emailPlaceholder: 'Email address',
    passwordPlaceholder: 'Old password',
    newPasswordPlaceholder: 'New password',
    confirmPasswordPlaceholder: 'Confirm password',
    logout: 'Log out'
  },
  profile: {
    back: 'Back',
    logout: 'Logout',
    user_profile: 'Profile',
    email: 'Email',
    nickname: 'Nickname',
    firstname: 'First name',
    lastname: 'Last name',
    full_name: 'Full name',
    user_info: 'User info',
    change_profile_password: 'Change profile password'
  },
  formRules: {
    required: 'This field is required',
    email: 'Please enter a valid email address',
    password: 'Please enter a valid password',
    passwordConfirm: 'Please enter a valid password confirmation',
    passwordMatch: 'Passwords do not match',
    passMinLen: 'Password cannot be less than {len} digits',
    minLength: 'Length should be more than {len}',
    confirmPasswordInvalid: 'Two inputs don\'t match!',
    confirmPassword: 'Please input the new password again',
    currentPassword: 'Please input your old password',
    newPassword: 'Please input new password'
  }
}
