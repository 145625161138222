import { getToken } from '@/utils/local-auth'
import serviceProvider from '@/utils/service-provider'

export class AuthService {
  constructor () {
    this.whiteList = ['/login', '/reset', '/403']
    this.loginUrls = ['/login', '/reset', '/password/set']
    this.store = serviceProvider.resolve('store')
    this.config = serviceProvider.resolve('config')
  }

  /**
   * Login user
   * @param username
   * @param password
   * @returns {Promise<minimist.Opts.unknown>}
   */
  async login (username, password) {
    await this.store.dispatch('user/login', {
      username,
      password
    })
    const user = await this.store.dispatch('user/getInfo')
    return this.userStatusChanged(user)
  }

  /**
   * Logout
   * @returns {*}
   */
  async logout () {
    const user = await this.store.dispatch('user/logout')
    return this.userStatusChanged(user)
  }

  /**
   * isAuthorized
   * @returns {boolean}
   */
  isAuthorized () {
    return getToken()
  }

  /**
   * inWhiteList
   * @param to
   * @return {boolean}
   */
  inWhiteList (to) {
    return this.whiteList.indexOf(to.matched[0] ? to.matched[0].path : '') !== -1
  }

  /**
   * userStatusChanged
   * @param user
   * @return {Promise<unknown>|*}
   */
  userStatusChanged (user) {
    if (this.config.inject.userStatusChanged) {
      return this.config.inject.userStatusChanged(user)
    }
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve()
      }, 1000)
    })
  }

  /**
   * getUser
   * @return {*}
   */
  getUser () {
    return this.store.getters['user/data']
  }

  /**
   * loadUser
   * @return {*}
   */
  async loadUser () {
    const user = await this.store.dispatch('user/getInfo')
    return this.userStatusChanged(user)
  }

  /**
   * getRoles
   * @return {[string, string]|[]|*}
   */
  getRoles () {
    return this.getUser().roles
  }

  /**
   * getPermissions
   * @return {[]|Permissions|*}
   */
  getPermissions () {
    return this.getUser().permissions
  }

  /**
   * hasRole
   * @param role
   * @return {*}
   */
  hasRole (role) {
    const roles = this.getRoles()
    return roles.includes(role)
  }

  /**
   * hasRoles
   * @param roles
   * @return {*}
   */
  hasRoles (roles) {
    const userRoles = this.getRoles()
    return roles.some(role => {
      return userRoles.includes(role)
    })
  }

  /**
   * hasPermission
   * @param permission
   * @return {*}
   */
  hasPermission (permission) {
    const userPermissions = this.getPermissions()
    return userPermissions.includes(permission)
  }

  /**
   * hasPermissions
   * @param permissions
   * @return {*}
   */
  hasPermissions (permissions) {
    const userPermissions = this.getRoles()
    return permissions.some(role => {
      return userPermissions.includes(role)
    })
  }

  /**
   * addBodyClasses
   * @param to
   */
  addBodyClasses (to) {
    // no redirect whitelist
    if (this.loginUrls.indexOf(to.matched[0] ? to.matched[0].path : '') !== -1) {
      document.body.className = 'login-page'
    }
  }

  /**
   * clearBodyClass
   * @param to
   */
  clearBodyClass (to) {
    const el = document.body
    const bodyClass = 'login-page'
    if (this.loginUrls.indexOf(to.matched[0] ? to.matched[0].path : '') !== -1) {
      el.className = el.className + ' ' + bodyClass
    } else if (el.classList.contains(bodyClass)) {
      el.classList.remove(bodyClass)
    }
  }
}
