/**
 * middlewarePipeline
 * @param context
 * @param middleware
 * @param index
 * @return {(function(): void)|*}
 */
export function middlewarePipeline (context, middleware, index) {
  const nextMiddleware = middleware[index]
  if (!nextMiddleware) {
    return context.next
  }
  return () => {
    const nextPipeline = middlewarePipeline(
      context, middleware, index + 1
    )
    nextMiddleware({
      ...context,
      next: nextPipeline
    })
  }
}

/**
 * middlewarePipelineRun
 * @param context
 * @param middlewares
 * @return {*}
 */
export function middlewarePipelineRun (context, middlewares) {
  if (!middlewares.length) {
    return context.next
  }
  return middlewares[0]({
    ...context,
    next: middlewarePipeline(context, middlewares, 1)
  })
}
