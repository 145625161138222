export class UserRepositoryContract {
  /**
   * resetPassword
   * @param email
   * @return Promise
   */
  resetPassword (email) {
    throw new Error('Not implemented yet!')
  }

  /**
   * setPassword
   * @param password
   * @param newPassword
   * @param confirmPassword
   * @return Promise
   */
  setPassword (password, newPassword, confirmPassword) {
    throw new Error('Not implemented yet!')
  }
}
