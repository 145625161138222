import axios from 'axios'

const service = axios.create({
  timeout: 60000
})
service.defaults.withCredentials = true

service.interceptors.response.use(
  response => {
    return response.data
  }
)
export default service
