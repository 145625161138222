/**
 * Check allowed user roles middleware
 * @param authService
 * @param redirect
 * @param next
 * @return {*}
 */
export default function ({
  authService,
  redirect,
  next,
  to
}) {
  const config = authService.config
  if (authService.isAuthorized() &&
    __isValidUser(authService.getUser()) &&
    !authService.hasPermission(config.inject.allowedPermission) &&
    to.path !== config.pages.forbidden.path) {
    return redirect(config.pages.forbidden.path)
  }
  return next()
}
/**
 * __isValidUser
 * @param user
 * @return {[string, string]|[]|[string, string]|*|boolean}
 */
function __isValidUser (user) {
  return user.roles && user.roles.length > 0
}
