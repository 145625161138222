import { AuthRepositoryContract } from '@/contracts/auth-repository-contract'

export class FakeAuthRepository extends AuthRepositoryContract {
  /**
   * Login
   * @param username
   * @param password
   * @returns {Promise<unknown>}
   */
  login (username, password) {
    console.log('login api call ...')
    return new Promise((resolve, reject) => {
      if (password === '11111') {
        resolve({
          data: {
            id: 15,
            name: 'munspel',
            email: 'andrey.lisnyak@gmail.com',
            roles: ['admin', 'agency'],
            password_expired: true,
            permissions: [
              'create.users',
              'view.users', 'edit.users', 'delete.users',
              'create.clients', 'view.clients', 'edit.clients',
              'delete.clients', 'create.permissions', 'view.permissions', 'edit.permissions',
              'delete.permissions', 'view.own.clients', 'create.own.clients', 'edit.own.clients',
              'delete.own.clients', 'create.orders', 'view.orders', 'edit.orders', 'delete.orders',
              'create.invoices', 'view.invoices', 'edit.invoices', 'delete.invoices', 'create.events', 'view.events', 'edit.events', 'delete.events', 'create.refund', 'view.own.events', 'create.own.invoices', 'view.own.invoices', 'create.agency.offers', 'view.agency.offers', 'edit.agency.offers', 'delete.agency.offers', 'create.products', 'view.products', 'edit.products', 'delete.products', 'import.products', 'view.commissions', 'view.own.commissions', 'edit.own.events'],
            avatar: 'https://www.gravatar.com/avatar/a96f1a79776ed03e71138eb424acbe94?s=80&d=mp&r=g'
          }
        }
        )
      } else {
        reject(new Error('Your credentials are invalid'))
      }
    })
  }

  /**
   * Logout
   * @returns {Promise<unknown>}
   */
  logout () {
    console.log('logout api call ...')
    return new Promise((resolve, reject) => {
      resolve('ok')
    })
  }

  getInfo () {
    console.log('get user info api call ...')
    return new Promise((resolve) => {
      resolve({
        data: {
          id: 15,
          name: 'munspel',
          email: 'andrey.lisnyak@gmail.com',
          roles: ['admin', 'agency'],
          password_expired: true,
          permissions: [
            'intranet',
            'create.users',
            'view.users', 'edit.users', 'delete.users',
            'create.clients', 'view.clients', 'edit.clients',
            'delete.clients', 'create.permissions', 'view.permissions', 'edit.permissions',
            'delete.permissions', 'view.own.clients', 'create.own.clients', 'edit.own.clients',
            'delete.own.clients', 'create.orders', 'view.orders', 'edit.orders', 'delete.orders',
            'create.invoices', 'view.invoices', 'edit.invoices', 'delete.invoices', 'create.events', 'view.events', 'edit.events', 'delete.events', 'create.refund', 'view.own.events', 'create.own.invoices', 'view.own.invoices', 'create.agency.offers', 'view.agency.offers', 'edit.agency.offers', 'delete.agency.offers', 'create.products', 'view.products', 'edit.products', 'delete.products', 'import.products', 'view.commissions', 'view.own.commissions', 'edit.own.events'],
          avatar: 'https://www.gravatar.com/avatar/a96f1a79776ed03e71138eb424acbe94?s=80&d=mp&r=g'
        }
      })
    })
  }

  csrf () {
    console.log('csrf api call ...')
    return new Promise((resolve, reject) => {
      return resolve()
    })
  }
}
