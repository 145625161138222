/**
 * Load user middleware
 * @param authService
 * @param redirect
 * @param next
 * @param to
 * @return {*}
 */
export default async function ({
  authService,
  redirect,
  to
}) {
  return redirect(await __loadUser(to, authService))
}

/**
 * __loadUser
 * @param to
 * @param authService
 * @return {Promise<(*&{replace: boolean})|string>}
 * @private
 */
async function __loadUser (to, authService) {
  try {
    await authService.loadUser()
    return {
      ...to,
      replace: true
    }
  } catch (error) {
    // remove token and go to login page to re-login
    await authService.logout()
    return `/login?redirect=${to.path}`
  }
}
