<template>
  <div class="error">
    <p class="error__title" >Error 403.</p>
    <p class="error__text" >Sorry, you don't have access to this page.</p>
    <p class="error__logout"><a href="#" @click.prevent.stop="logOut">Login with another credentials</a></p>
  </div>
</template>

<script>
export default {
  name: 'Error403',
  methods: {
    async logOut () {
      await this.$auth.logout()
      this.$router.push({ path: '/' }).catch(() => {})
    }
  }
}
</script>

<style scoped>

body {
  height: 100vh;
  min-height: 100vh;
}
.error {
  color: #636b6f;
  font-family: sans-serif;
  height: 100vh;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
.error__title {
  font-size: 2rem;
  font-weight: bold;
  color: #889aa4;
  margin-bottom: 1rem;
}
.error__text {
  font-size: 1.5rem;
  font-weight: bold;
  color: #889aa4;
}
.error__logout{
  margin-top: 1rem;
}
.error__logout a,
.error__logout a:hover,
.error__logout a:visited {
  color: #889aa4;
}
</style>
