export class AuthRepositoryContract {
  /**
   * login
   * @param user
   * @param password
   * @return Promise
   */
  login (user, password) {
    throw new Error('Not implemented yet!')
  }

  /**
   * getInfo
   * @return Promise
   */
  getInfo () {
    throw new Error('Not implemented yet!')
  }

  /**
   * logout
   * @return Promise
   */
  logout () {
    throw new Error('Not implemented yet!')
  }

  /**
   * @return Promise
   */
  csrf () {
    throw new Error('Not implemented yet!')
  }
}
