import { AuthRepositoryContract } from '@/contracts/auth-repository-contract'

export class AuthRepository extends AuthRepositoryContract {
  constructor (baseUrl, httpClien) {
    super()
    this.__BASE_URL = baseUrl.trim('/')
    this.__httpClient = httpClien
  }

  /**
   * login
   * @param user
   * @param password
   * @return {Promise}
   */
  login (user, password) {
    return this.__httpClient({
      url: this.__BASE_URL + '/spa/login',
      method: 'post',
      data: {
        email: user,
        password: password
      }
    })
  }

  /**
   * getInfo
   * @return {Promise}
   */
  getInfo () {
    return this.__httpClient({
      url: this.__BASE_URL + '/spa/user',
      method: 'get'
    })
  }

  /**
   * logout
   * @return {Promise}
   */
  logout () {
    return this.__httpClient({
      url: this.__BASE_URL + '/spa/logout',
      method: 'post'
    })
  }

  /**
   * csrf
   * @return {Promise}
   */
  csrf () {
    return this.__httpClient({
      url: this.__BASE_URL + '/spa/csrf-cookie',
      method: 'get'
    })
  }
}
