<template>
  <el-form ref="form" class="login-form" auto-complete="on" label-position="left"
           :rules="rules" :model="form" data-form-type="change_password">
    <h5 class="sub-title">{{ $t("setPassword.subTitle") }}</h5>

    <el-form-item prop="password" required>
      <el-input
        id="old-password"
        v-model="form.password"
        type="password"
        :placeholder="$t('setPassword.passwordPlaceholder')"
        autocomplete="off"
        data-form-type="password"
      >
        <template slot="prepend">
          <span class="svg-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
              <path
                d="M108.8 44.322H89.6v-5.36c0-9.04-3.308-24.163-25.6-24.163-23.145 0-25.6 16.881-25.6 24.162v5.361H19.2v-5.36C19.2 15.281 36.798 0 64 0c27.202 0 44.8 15.281 44.8 38.961v5.361zm-32 39.356c0-5.44-5.763-9.832-12.8-9.832-7.037 0-12.8 4.392-12.8 9.832 0 3.682 2.567 6.808 6.407 8.477v11.205c0 2.718 2.875 4.962 6.4 4.962 3.524 0 6.4-2.244 6.4-4.962V92.155c3.833-1.669 6.393-4.795 6.393-8.477zM128 64v49.201c0 8.158-8.645 14.799-19.2 14.799H19.2C8.651 128 0 121.359 0 113.201V64c0-8.153 8.645-14.799 19.2-14.799h89.6c10.555 0 19.2 6.646 19.2 14.799z"/>
            </svg>
          </span>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="new_password" required>
      <el-input
        id="new-password"
        v-model="form.new_password"
        type="password"
        :placeholder="$t('setPassword.newPasswordPlaceholder')"
        autocomplete="off"
        data-form-type="password,new"
      >
        <template slot="prepend">
          <span class="svg-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
              <path
                  d="M108.8 44.322H89.6v-5.36c0-9.04-3.308-24.163-25.6-24.163-23.145 0-25.6 16.881-25.6 24.162v5.361H19.2v-5.36C19.2 15.281 36.798 0 64 0c27.202 0 44.8 15.281 44.8 38.961v5.361zm-32 39.356c0-5.44-5.763-9.832-12.8-9.832-7.037 0-12.8 4.392-12.8 9.832 0 3.682 2.567 6.808 6.407 8.477v11.205c0 2.718 2.875 4.962 6.4 4.962 3.524 0 6.4-2.244 6.4-4.962V92.155c3.833-1.669 6.393-4.795 6.393-8.477zM128 64v49.201c0 8.158-8.645 14.799-19.2 14.799H19.2C8.651 128 0 121.359 0 113.201V64c0-8.153 8.645-14.799 19.2-14.799h89.6c10.555 0 19.2 6.646 19.2 14.799z"/>
            </svg>
          </span>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="confirm_password" required>
      <el-input
        id="new-password-repeat"
        v-model="form.confirm_password"
        type="password"
        :placeholder="$t('setPassword.confirmPasswordPlaceholder')"
        autocomplete="off"
        data-form-type="password,new,confirmation"
      >
        <template slot="prepend">
          <span class="svg-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
              <path
                  d="M108.8 44.322H89.6v-5.36c0-9.04-3.308-24.163-25.6-24.163-23.145 0-25.6 16.881-25.6 24.162v5.361H19.2v-5.36C19.2 15.281 36.798 0 64 0c27.202 0 44.8 15.281 44.8 38.961v5.361zm-32 39.356c0-5.44-5.763-9.832-12.8-9.832-7.037 0-12.8 4.392-12.8 9.832 0 3.682 2.567 6.808 6.407 8.477v11.205c0 2.718 2.875 4.962 6.4 4.962 3.524 0 6.4-2.244 6.4-4.962V92.155c3.833-1.669 6.393-4.795 6.393-8.477zM128 64v49.201c0 8.158-8.645 14.799-19.2 14.799H19.2C8.651 128 0 121.359 0 113.201V64c0-8.153 8.645-14.799 19.2-14.799h89.6c10.555 0 19.2 6.646 19.2 14.799z"/>
            </svg>
          </span>
        </template>
      </el-input>
    </el-form-item>
    <div style="text-align: right;">
      <el-button
        type="primary"
        :disabled="loading"
        :loading="loading"
        @click.prevent="change()"
      >
        {{ $t('setPassword.submit') }}
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { getMessage } from '@/utils/errors'

export default {
  name: 'SetPasswordForProfile',
  head () {
    return {
      title: 'Set password'
    }
  },
  data () {
    return {
      form: {
        password: '',
        new_password: '',
        confirm_password: ''
      },
      redirect: null,
      loading: false,
      rules: {
        email: [{ required: true, message: this.$t('formRules.email'), trigger: 'blur' }],
        password: [
          { required: true, message: this.$t('formRules.currentPassword'), trigger: 'blur' }
        ],
        new_password: [
          { required: true, message: this.$t('formRules.newPassword'), trigger: 'blur' },
          { min: 8, message: this.$t('formRules.minLength', { len: 8 }), trigger: 'blur' }
        ],
        confirm_password: [
          { required: true, message: this.$t('formRules.confirmPassword'), trigger: 'blur' },
          { min: 8, message: this.$t('formRules.minLength', { len: 8 }), trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.new_password) {
                callback(new Error(this.$t('formRules.confirmPasswordInvalid')))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    change () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        this.loading = true
        this.$store.dispatch('user/setPassword', {
          password: this.form.password,
          newPassword: this.form.new_password,
          confirmPassword: this.form.confirm_password
        })
          .then(async (response) => {
            this.$message.success(response.message)
            await this.$auth.logout()
            this.$router.push({ path: this.redirect || '/' }).catch(() => {})
            this.loading = false
          }).catch((err) => {
            this.$message.error(getMessage(err))
            if (err.response && err.response.data.errors) {
              this.showErrors(err.response.data.errors)
            }
            this.loading = false
          })
      })
    },
    showErrors (errors) {
      const fields = this.$refs.form.fields
      for (const field in errors) {
        const formfield = fields.find(row => row.prop === field)
        if (formfield) {
          formfield.validateState = 'error'
          formfield.validateMessage = errors[field].pop()
        }
      }
    }
  }
}
</script>
