import serviceProvider from '@/utils/service-provider'
import NProgress from 'nprogress'
import { middlewarePipelineRun } from '@/middlewares/middleware-pipeline'

import authMiddleware from '@/middlewares/authorized-middleware'
import checkRolesMiddleware from '@/middlewares/check-role-middleware'
import redirectMiddleware from '@/middlewares/redirect-if-authenticated'
import expiredMiddleware from '@/middlewares/expired-middleware'
import loadUserMiddlleware from '@/middlewares/load-user-middleware'
/**
 * beforeEach
 * @param to
 * @param from
 * @param next
 * @return {Promise<*>}
 */
async function beforeEach (to, from, next) {
  NProgress.start()
  const authService = serviceProvider.resolve('auth')
  const context = { to, from, next, authService, redirect: next }
  const defaultMiddlewares = [
    checkRolesMiddleware,
    authMiddleware,
    redirectMiddleware,
    expiredMiddleware,
    loadUserMiddlleware
  ]
  return middlewarePipelineRun(context, defaultMiddlewares)
}

/**
 * afterEach
 */
function afterEach () {
  NProgress.done()
}

export { beforeEach, afterEach }
