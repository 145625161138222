import { UserRepositoryContract } from '@/contracts/user-repository-contract'

export class FakeUserRepository extends UserRepositoryContract {
  /**
   * resetPassword
   * @param email
   * @return Promise
   */
  resetPassword (email) {
    console.log('resetPassword', email)
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error('Not implemented yet!'))
      }, 1000)
    })
  }

  /**
   * setPassword
   * @param password
   * @param newPassword
   * @param confirmPassword
   * @return Promise
   */
  setPassword (password, newPassword, confirmPassword) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error('Not implemented yet!'))
      }, 1000)
    })
  }

  /**
   * userStatusChanged
   * @param user
   * @return {Promise<unknown>}
   */
  userStatusChanged (user) {
    console.log('userStatusChanged:', user)
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('User loaded ...', user)
        resolve()
      }, 1000)
    })
  }
}
