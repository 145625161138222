<template>
  <div class="login-container">
    <el-form ref="resetForm" class="login-form" :model="resetForm" auto-complete="on" label-position="left">
      <div class="form-header">
        <div class="logo"></div>
        <div class="titles">
          <h3 class="title">
            {{ $t('resetPassword.title') }}
          </h3>
          <h5 class="sub-title">{{ $t('resetPassword.subTitle') }}</h5>
        </div>
      </div>
      <el-form-item
        prop="email"
        :rules="[
              { required: true, message: $t('formRules.required'), trigger: 'blur' },
              { type: 'email', message: $t('formRules.email'), trigger: ['blur', 'change'] }
          ]">
        <span class="svg-container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 96" id="email">
          <path
            d="M64.125 56.975L120.188.912A12.476 12.476 0 00115.5 0h-103c-1.588 0-3.113.3-4.513.838l56.138 56.137z"></path>
          <path
            d="M64.125 68.287l-62.3-62.3A12.42 12.42 0 000 12.5v71C0 90.4 5.6 96 12.5 96h103c6.9 0 12.5-5.6 12.5-12.5v-71a12.47 12.47 0 00-1.737-6.35L64.125 68.287z"></path>
        </svg>
        </span>
        <el-input
          v-model="resetForm.email"
          name="email"
          auto-complete="on"
          :placeholder="$t('resetPassword.emailPlaceholder')"
        />
      </el-form-item>
      <el-form-item>
        <el-button :disabled="loading" :loading="loading" type="primary" style="width:100%;"
                   @click.native.prevent="reset">
          {{ $t("resetPassword.submit") }}
        </el-button>
      </el-form-item>

      <a v-if="externalLoginPage" :href="externalLoginPage" class="sub-title">
        {{ $t('resetPassword.backToLogin') }}
      </a>
      <router-link v-else :to="{path: '/login', query: {redirect: redirect}}" class="sub-title">
        {{ $t('resetPassword.backToLogin') }}
      </router-link>
    </el-form>
  </div>
</template>

<script>
import { getMessage } from '@/utils/errors'

export default {
  name: 'Reset',
  layout: 'login',
  data () {
    return {
      resetForm: {
        email: ''
      },
      redirect: null,
      loading: false
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  computed: {
    externalLoginPage () {
      return this.$route.query?.logout_redirect
    }
  },
  methods: {

    getFirstError (data) {
      let response = ''
      Object.entries(data).forEach(error => {
        response = error.pop()
        return false
      })
      return response
    },
    reset () {
      this.$refs.resetForm.validate((valid) => {
        if (!valid) return
        this.loading = true
        this.$store.dispatch('user/resetPassword', this.resetForm.email).then((response) => {
          this.email = ''
          this.$message.success(response.message)
          this.loading = false
        }).catch((err) => {
          this.$message.error(getMessage(err))
          this.loading = false
        })
      })
    }
  }
}
</script>
