import httpClient from '@/utils/http-client'
import { AuthRepository } from '@/services/auth-repository'
import { UserRepository } from '@/services/user-repository'
import { FakeAuthRepository } from '@/services/fake-auth-repository'
import { FakeUserRepository } from '@/services/fake-user-repository'
export class ApiFactory {
  static AUTH_API='authApi'
  static USER_API='userApi'

  /**
   * makeApi
   * @param apiName
   * @param options
   * @return {FakeUserRepository|FakeUserRepository|AuthRepository|UserRepository|FakeAuthRepository|FakeAuthRepository}
   */
  static makeApi (apiName, options) {
    const baseUrl = options.baseApiUrl
    if (baseUrl && apiName === ApiFactory.AUTH_API) {
      return new AuthRepository(baseUrl, httpClient)
    }
    if (baseUrl && apiName === ApiFactory.USER_API) {
      return new UserRepository(baseUrl, httpClient)
    }
    if (apiName === ApiFactory.AUTH_API) {
      return options.inject.authApi ? options.inject.authApi : new FakeAuthRepository()
    }
    if (apiName === ApiFactory.USER_API) {
      return options.inject.userApi ? options.inject.userApi : new FakeUserRepository()
    }
    throw new Error('Wrong factory call!')
  }
}
