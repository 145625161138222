import { UserRepositoryContract } from '@/contracts/user-repository-contract'

export class UserRepository extends UserRepositoryContract {
  constructor (baseUrl, httpClien) {
    super()
    this.__BASE_URL = baseUrl.trim('/')
    this.__httpClient = httpClien
  }

  /**
   * resetPassword
   * @param email
   * @return {Promise}
   */
  resetPassword (email) {
    return this.__httpClient({
      url: this.__BASE_URL + '/spa/reset-password',
      method: 'post',
      data: {
        email: email
      }
    })
  }

  /**
   * setPassword
   * @param password
   * @param new_password
   * @param confirm_password
   * @return {Promise}
   */
  // eslint-disable-next-line camelcase
  setPassword (password, new_password, confirm_password) {
    return this.__httpClient({
      url: this.__BASE_URL + '/spa/set-password',
      method: 'post',
      data: {
        password: password,
        new_password: new_password,
        confirm_password: confirm_password
      }
    })
  }
}
