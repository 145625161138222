/**
 * getMessage
 * @param err
 * @returns {string}
 */
export function getMessage (err) {
  if (err && err.response && err.response.data && err.response.data.message) {
    return err.response.data.message
  }
  return 'Unknown error type!'
}

/**
 * getFirstError
 * @param err
 * @returns {string}
 */
export function getFirstError (err) {
  const errors = getErrors(err)
  return errors.pop()
}

/**
 * getErrors
 * @param err
 * @returns {[]}
 */
export function getErrors (err) {
  const res = []
  if (!(err && err.response && err.response.data &&
    (err.response.data.errors || err.response.data.message))) {
    res.push(err.name + ': ' + err.message)
    return res
  }
  for (const prop in err.response.data.errors) {
    err.response.data.errors[prop].forEach((msg) => {
      res.push(msg)
    })
  }
  if (res.length === 0 && err.response.data.message) {
    res.push(err.response.data.message)
  }
  return res
}
