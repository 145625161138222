/**
 * Is user expired middleware
 * @param authService
 * @param redirect
 * @param next
 * @param to
 * @param from
 * @return {*}
 */
export default function ({
  authService,
  redirect,
  next,
  to,
  from
}) {
  const user = authService.getUser()
  if (__isValidUser(user)) {
    return redirect(__checkExpireUser(user, to, from, authService))
  }
  return next()
}

/**
 * __checkExpireUser
 * @param user
 * @param to
 * @return {string|{path: string}|null}
 * @private
 */
function __checkExpireUser (user, to, from, authService) {
  if (user.password_expired && to.path !== '/password/set') {
    return { path: '/password/set', query: { ...to.query, ...from.query } }
  } else if (!user.password_expired && to.path === '/password/set') {
    return { path: '/' }
  }
  authService.clearBodyClass(to)
}

/**
 * __isValidUser
 * @param user
 * @return {[string, string]|[]|[string, string]|*|boolean}
 */
function __isValidUser (user) {
  return user.roles && user.roles.length > 0
}
