import Cookies from 'js-cookie'
/* eslint-disable */
const TokenKey = 'isLogged'
const appPath = resolveUrlPatch(location.href)
console.log('appPath: ', appPath)

export function getToken () {
  return Cookies.get(TokenKey) === '1'
}

export function setToken (token, cookiesPath = null, cookiesDomain = null) {
  return Cookies.set(TokenKey, token, { path: cookiesPath || appPath, domain: cookiesDomain })
}

export function removeToken (cookiesPath = null, cookiesDomain = null) {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  const mainDomain = parts.slice(-2).join('.');
  Cookies.remove(TokenKey, { path: cookiesPath || appPath, domain: hostname });
  Cookies.remove(TokenKey, { path: cookiesPath || appPath, domain: mainDomain });
  Cookies.remove(TokenKey, { domain: hostname });
  Cookies.remove(TokenKey, { domain: mainDomain });
  Cookies.remove(TokenKey, { path: cookiesPath || appPath, domain: cookiesDomain });
}

/**
 * resolveUrlPatch
 * @param str
 * @return {string}
 */
function resolveUrlPatch (str) {
  return '/'
}
