<template>
<div class="login-container">
  <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on"
           label-position="left" data-form-type="login">
    <div class="form-header">
      <div class="logo"></div>
      <div class="titles">
        <h3 class="title">
          {{ $t('login.formTitle') }}
        </h3>
        <h5 class="sub-title">{{ $t('login.signIn') }}</h5>
      </div>
    </div>
    <el-form-item prop="email">
        <span class="svg-container">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 130"><path
            d="M63.444 64.996c20.633 0 37.359-14.308 37.359-31.953 0-17.649-16.726-31.952-37.359-31.952-20.631 0-37.36 14.303-37.358 31.952 0 17.645 16.727 31.953 37.359 31.953zM80.57 75.65H49.434c-26.652 0-48.26 18.477-48.26 41.27v2.664c0 9.316 21.608 9.325 48.26 9.325H80.57c26.649 0 48.256-.344 48.256-9.325v-2.663c0-22.794-21.605-41.271-48.256-41.271z"
            stroke="#979797"></path>
          </svg>
        </span>
      <el-input
        v-model="loginForm.email"
        name="email" type="text"
        auto-complete="on"
        :placeholder="$t('login.email')"
        data-form-type="username"
        @keydown.native="checkCapsLock"
      />
    </el-form-item>
    <el-form-item prop="password">
          <span class="svg-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
            <path
              d="M108.8 44.322H89.6v-5.36c0-9.04-3.308-24.163-25.6-24.163-23.145 0-25.6 16.881-25.6 24.162v5.361H19.2v-5.36C19.2 15.281 36.798 0 64 0c27.202 0 44.8 15.281 44.8 38.961v5.361zm-32 39.356c0-5.44-5.763-9.832-12.8-9.832-7.037 0-12.8 4.392-12.8 9.832 0 3.682 2.567 6.808 6.407 8.477v11.205c0 2.718 2.875 4.962 6.4 4.962 3.524 0 6.4-2.244 6.4-4.962V92.155c3.833-1.669 6.393-4.795 6.393-8.477zM128 64v49.201c0 8.158-8.645 14.799-19.2 14.799H19.2C8.651 128 0 121.359 0 113.201V64c0-8.153 8.645-14.799 19.2-14.799h89.6c10.555 0 19.2 6.646 19.2 14.799z"/>
            </svg>
          </span>
        <el-input
          v-model="loginForm.password"
          :type="pwdType"
          name="password"
          auto-complete="on"
          :placeholder="$t('login.password')"
          @keyup.enter.native="handleLogin"
          @keydown.native="checkCapsLock"
          data-form-type="password"
        />
        <span class="show-pwd" @click="showPwd">
            <svg v-if="pwdType==='password'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 64" id="eye">
              <path
                d="M127.072 7.994c1.37-2.208.914-5.152-.914-6.87-2.056-1.717-4.797-1.226-6.396.982-.229.245-25.586 32.382-55.74 32.382-29.24 0-55.74-32.382-55.968-32.627-1.6-1.963-4.57-2.208-6.397-.49C-.17 3.086-.399 6.275 1.2 8.238c.457.736 5.94 7.36 14.62 14.72L4.17 35.96c-1.828 1.963-1.6 5.152.228 6.87.457.98 1.6 1.471 2.742 1.471s2.284-.49 3.198-1.472l12.564-13.983c5.94 4.416 13.021 8.587 20.788 11.53l-4.797 17.418c-.685 2.699.686 5.397 3.198 6.133h1.37c2.057 0 3.884-1.472 4.341-3.68L52.6 42.83c3.655.736 7.538 1.227 11.422 1.227 3.883 0 7.767-.49 11.422-1.227l4.797 17.173c.457 2.208 2.513 3.68 4.34 3.68.457 0 .914 0 1.143-.246 2.513-.736 3.883-3.434 3.198-6.133l-4.797-17.172c7.767-2.944 14.848-7.114 20.788-11.53l12.336 13.738c.913.981 2.056 1.472 3.198 1.472s2.284-.49 3.198-1.472c1.828-1.963 1.828-4.906.228-6.87l-11.65-13.001c9.366-7.36 14.849-14.474 14.849-14.474z"/>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" id="eye-open">
              <path
                d="M512 128q69.675 0 135.51 21.163t115.498 54.997 93.483 74.837 73.685 82.006 51.67 74.837 32.17 54.827L1024 512q-2.347 4.992-6.315 13.483T998.87 560.17t-31.658 51.669-44.331 59.99-56.832 64.34-69.504 60.16-82.347 51.5-94.848 34.687T512 896q-69.675 0-135.51-21.163t-115.498-54.826-93.483-74.326-73.685-81.493-51.67-74.496-32.17-54.997L0 513.707q2.347-4.992 6.315-13.483t18.816-34.816 31.658-51.84 44.331-60.33 56.832-64.683 69.504-60.331 82.347-51.84 94.848-34.816T512 128.085zm0 85.333q-46.677 0-91.648 12.331t-81.152 31.83-70.656 47.146-59.648 54.485-48.853 57.686-37.675 52.821-26.325 43.99q12.33 21.674 26.325 43.52t37.675 52.351 48.853 57.003 59.648 53.845T339.2 767.02t81.152 31.488T512 810.667t91.648-12.331 81.152-31.659 70.656-46.848 59.648-54.186 48.853-57.344 37.675-52.651T927.957 512q-12.33-21.675-26.325-43.648t-37.675-52.65-48.853-57.345-59.648-54.186-70.656-46.848-81.152-31.659T512 213.334zm0 128q70.656 0 120.661 50.006T682.667 512 632.66 632.661 512 682.667 391.339 632.66 341.333 512t50.006-120.661T512 341.333zm0 85.334q-35.328 0-60.33 25.002T426.666 512t25.002 60.33T512 597.334t60.33-25.002T597.334 512t-25.002-60.33T512 426.666z"/>
            </svg>
        </span>
    </el-form-item>
    <el-alert v-if="capsLockOn" :title="$t('login.capsLockOn')" type="warning" show-icon style="margin-bottom: 22px;"/>
    <el-form-item>
      <el-button :loading="loading" type="primary" style="width:100%;" @click.native.prevent="handleLogin">
        {{$t("login.signInCaption")}}
      </el-button>
    </el-form-item>
    <router-link :to="{name: 'reset.password', query: {redirect: redirect, logout_redirect: $route.query?.logout_redirect  }}" class="sub-title">
      {{ $t('login.forgotPassword') }}
    </router-link>
  </el-form>

</div>
</template>

<script>
import { getFirstError } from '@/utils/errors'

export default {
  name: 'Login',
  layout: 'login',
  components: {},
  data () {
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('formRules.required')))
      } else {
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value.length < 4) {
        callback(new Error(this.$t('formRules.passMinLen', { len: 4 })))
      } else {
        callback()
      }
    }
    return {
      capsLockOn: false,
      loginForm: {
        email: '',
        password: ''
      },
      loginRules: {
        email: [{
          required: true,
          trigger: 'blur',
          validator: validateEmail
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePass
        }]
      },
      loading: false,
      pwdType: 'password',
      redirect: undefined
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  methods: {
    checkCapsLock (e) {
      this.capsLockOn = !!(e && typeof e.getModifierState === 'function' && e.getModifierState('CapsLock'))
    },
    showPwd () {
      if (this.pwdType === 'password') {
        this.pwdType = ''
      } else {
        this.pwdType = 'password'
      }
    },
    handleLogin () {
      this.$refs.loginForm.validate(valid => {
        if (!valid) {
          return false
        }
        this.loading = true
        this.$auth.login(this.loginForm.email, this.loginForm.password)
          .then(() => {
            this.$router.push({ path: this.redirect ? this.redirect.trim() : '/' }).catch((e) => {})
            this.loading = false
          })
          .catch((error) => {
            this.$message({
              message: getFirstError(error),
              type: 'error'
            })
            this.loading = false
          })
      })
    }
  }
}
</script>
