export default {
  _services: {},
  /**
   * bind
   * @param name
   * @param value
   */
  bind: function (name, value) {
    this._services[name] = value
  },
  /**
   * resolve
   * @param name
   * @returns {*}
   */
  resolve: function (name) {
    if (this._services[name]) {
      return this._services[name]
    }
    return null
  }
}
