import LoginForm from '@/components/Login'
import ResetPassword from '@/components/ResetPassword'
import SetPassword from '@/components/SetPassword'
import userModule from '@/store/modules/user'
import { afterEach, beforeEach } from '@/permission'
import { AuthService } from '@/services/auth-service'
import serviceProvider from '@/utils/service-provider'
import defaultConfig from '@/defaultConfig'
import { ApiFactory } from '@/utils/api-factory'
import localeEn from '@/lang/en'
import localeDe from '@/lang/de'
import Error403 from '@/views/Error403'
import packageInfo from '../package.json'

function validateConfig (config) {
  if (!config.inject.router) {
    console.error('Vue router has not been set!')
  }
  if (!config.inject.store) {
    console.error('Vue store has not been set!')
  }
}

function makeBindings (options) {
  serviceProvider.bind('config', options)
  serviceProvider.bind('page-title-resolver', options.inject.pageTitleResolver)
  serviceProvider.bind('router', options.inject.router)
  serviceProvider.bind('store', options.inject.store)
  serviceProvider.bind('auth-api', ApiFactory.makeApi(ApiFactory.AUTH_API, options))
  serviceProvider.bind('user-api', ApiFactory.makeApi(ApiFactory.USER_API, options))
  serviceProvider.bind('auth', new AuthService(options))
}

/**
 * mergeConfigs
 * @param options
 * @return {{pages: {resetPassword: {path: string, name: string, title: string}, setPassword: {path: string, name: string, title: string}, login: {path: string, name: string, title: string}}, inject: {router: null, authApi: FakeAuthRepository, userApi: FakeUserRepository, pageTitleResolver: function(*): *, store: null}}}
 */
function mergeConfigs (options) {
  const config = defaultConfig
  options.inject = options.inject || {}
  config.cookiesPath = options.cookiesPath || config.cookiesPath
  config.cookiesDomain = options.cookiesDomain || config.cookiesDomain
  config.inject = { ...defaultConfig.inject, ...options.inject }
  config.baseApiUrl = options.baseApiUrl ? options.baseApiUrl : defaultConfig.baseApiUrl
  config.pages.login = { ...defaultConfig.pages.login, ...options.pages.login }
  config.pages.resetPassword = { ...defaultConfig.pages.resetPassword, ...options.pages.resetPassword }
  config.pages.setPassword = { ...defaultConfig.pages.setPassword, ...options.pages.setPassword }
  if (options.inject.i18n) {
    config.inject.i18n = options.inject.i18n
    config.inject.i18n.mergeLocaleMessage('en', localeEn)
    config.inject.i18n.mergeLocaleMessage('de', localeDe)
  }
  return config
}

/**
 * install plugin
 * @param Vue
 * @param options
 */
function install (Vue, options) {
  console.log(packageInfo.version)
  const config = mergeConfigs(options)
  if (install.installed) return
  validateConfig(config)
  makeBindings(config)
  require('./assets/scss/index.scss')
  Vue.prototype.$auth = serviceProvider.resolve('auth')
  config.inject.store.registerModule('user', userModule)
  config.inject.router.beforeEach(beforeEach)
  config.inject.router.afterEach(afterEach)
  addAuthRoutes(config.inject.router)
  install.installed = true
}

/**
 * addAuthRoutes
 * @param router
 */
function addAuthRoutes (router) {
  const config = serviceProvider.resolve('config')
  router.addRoute({
    path: config.pages.login.path,
    name: config.pages.login.name,
    component: LoginForm,
    hidden: true,
    meta: {
      title: config.pages.login.title
    }
  })
  router.addRoute({
    path: config.pages.resetPassword.path,
    name: config.pages.resetPassword.name,
    component: ResetPassword,
    hidden: true,
    meta: {
      title: config.pages.resetPassword.title
    }
  })
  router.addRoute({
    path: config.pages.setPassword.path,
    name: config.pages.setPassword.name,
    component: SetPassword,
    hidden: true,
    meta: {
      title: config.pages.setPassword.title
    }
  })
  router.addRoute({
    path: config.pages.forbidden.path,
    name: config.pages.forbidden.name,
    component: Error403,
    hidden: true,
    meta: {
      title: config.pages.forbidden.title
    }
  })
}

export default {
  install
}
export { default as AuthProfile } from './components/AuthProfile.vue'
