<template>
    <el-main class="profile-container">
      <el-card shadow="always">
        <div slot="header" class="clearfix">
          <div class="el-page-header">
            <div class="el-page-header__left" @click="$router.go(-1)">
              <i class="el-icon-back" />
              <div class="el-page-header__title">{{$t('profile.back')}}</div>
            </div>
            <span>{{ $t('profile.user_profile') }}</span>
          </div>
        </div>
        <el-row>
          <el-col :xs="24" :span="4">
            <el-avatar :size="100" :src="$store.state.user.avatar"></el-avatar>
          </el-col>
          <el-col :xs="24" :span="10">
            <el-descriptions :title="$t('profile.user_info')" :column="1">
              <el-descriptions-item :label="$t('profile.nickname')">{{ $store.state.user.name }} </el-descriptions-item>
              <el-descriptions-item :label="$t('profile.email')"> {{ $store.state.user.email}} </el-descriptions-item>
              <el-descriptions-item :label="$t('profile.full_name')"> {{ $store.state.user.full_name}} </el-descriptions-item>
            </el-descriptions>
          </el-col>
          <el-col :xs="24" :span="10">
            {{ $tc('profile.change_profile_password') }}
            <set-password-for-profile/>
          </el-col>
        </el-row>
      </el-card>
    </el-main>
</template>

<script>
import SetPasswordForProfile from '@/components/SetPasswordForProfile'

export default {
  name: 'AuthProfile',
  components: { SetPasswordForProfile },
  data () {
    return {
      loading: false
    }
  }
}
</script>

<style scoped>

</style>
