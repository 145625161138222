import { FakeAuthRepository } from '@/services/fake-auth-repository'
import { FakeUserRepository } from '@/services/fake-user-repository'

export default {
  inject: {
    store: null,
    router: null,
    authApi: new FakeAuthRepository(),
    userApi: new FakeUserRepository(),
    baseApiUrl: null,
    i18n: (key, value) => { return key },
    userStatusChanged: null,
    allowedPermission: 'intranet',
    cookiesPath: '/',
    cookiesDomain: null
  },
  pages: {
    login: {
      title: 'Login Title',
      name: 'login',
      path: '/login'
    },
    resetPassword: {
      title: 'Reset password',
      path: '/reset',
      name: 'reset.password'
    },
    setPassword: {
      title: 'Set password',
      name: 'set.password',
      path: '/password/set'
    },
    forbidden: {
      title: 'Forbidden',
      name: 'forbidden',
      path: '/403'
    }
  }
}
