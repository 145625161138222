/**
 * RedirectIfAuthenticated
 * @param authService
 * @param redirect
 * @param next
 * @param to
 * @return {*}
 */
export default function ({
  authService,
  redirect,
  next,
  to
}) {
  if (authService.isAuthorized() && to.path === '/login') {
    return redirect({ path: '/' })
  }
  return next()
}
